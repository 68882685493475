.event-name {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
}

.event-date {
  margin-top: 10px;
  margin-bottom: 8px;
  font-size: 13px;
  color: darkslategray;
}

.event-place {
  margin: 0;
  font-size: 13px;
  color: darkslategray;
  text-align: center;
}

.line-separator {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
}

.payment-form-container {
  margin-top: 10px;
  width: 90%;
}

.payment-info-header {
  margin: 0;
  margin-top: 15px;
  font-size: 15px;
}

.ticket-info-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 5px;

  .ticket-info-header {
    margin-bottom: 7px;
    font-size: 15px;
    text-align: left;
  }

  .ticket-info-details {
    font-size: 13.5px;
  }
  .ticket-disclaimer {
    font-size: 12.5px;
    color: black;
    margin-top: 7px;
  }
}

.payment-form {
  margin: 20px;

  .payment-button {
    background-color: #635bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    margin-top: 15px;
    font-size: 15px;
    font-weight: bold;
    // margin-bottom: 10px;
    transition: all 0.2s ease-in-out;
    width: 100%;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  .error-message-payment {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
}

.text-container {
  margin-top: 10px;
  text-align: center;

  .venue-text {
    font-size: 12px;
    margin-bottom: 0;
  }
}
