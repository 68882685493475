.profile-card {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 35px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-info-card {
  position: relative;
  z-index: 2;
  background-color: #f8f9fa;
}
