.social-media-bar {
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  z-index: 1;
  background-color: white;
  border-radius: 110px;
  align-items: center;
  flex-direction: column;
  display: flex;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.social-media-link {
  display: block;
  width: 60px;
  height: 55px;
  margin-bottom: 2px;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  text-decoration: none;
  font-size: 24px;
}

.social-heading {
  font-size: 16px;
  font-weight: 600;
}

.icon {
  color: #333;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #007bff;
}

.instagram {
  color: #c13584;
}

.facebook {
  color: #3b5998;
}

.tiktok {
  color: #69c9d0;
}

.youtube {
  color: #ff0000;
}

.whatsapp {
  color: #25d366;
}

.social-media-links-container {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 368px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.social-media-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 5px;
}
