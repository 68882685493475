/* ErrorPage.css */
.error-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.error-page-container h1 {
  font-size: 5rem;
  margin: 0;
  color: #404040;
}

.error-page-container p {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: #808080;
}
