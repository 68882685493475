.profile-card {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 35px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.payment-success-container {
  text-align: center;
}

.line-separator {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
}

.success-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: radial-gradient(circle, #a8e6cf, #56b894);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px auto;
}

.checkmark {
  font-size: 40px;
  color: white;
}

h2 {
  color: #008080;
}

.success-text {
  font-size: 18px;
  font-weight: bold;
}

.amount {
  color: #45a3e5;
}

.pay-method {
  font-weight: bold;
}

.ticket-name {
  color: #00aaff;
  font-weight: bold;
}

.view-ticket-button {
  background-color: #56b894;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  margin-top: 15px;
  font-size: 15px;
  font-weight: bold;
  // margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}
