.event-information {
  font-weight: Bold;
  font-size: 20px;
  text-align: center;

  .event-name {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
  }

  .event-date {
    margin-top: 10px;
    margin-bottom: 8px;
    font-size: 13px;
    color: darkslategray;
  }

  .event-location {
    margin: 0;
    font-size: 13px;
    color: darkslategray;
  }
  // .invited-by {
  //   text-align: center;
  //   font-size: 17px;
  //   color: #555;
  // }
}

.profile-image img {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 130px;
  height: 130px;
  object-fit: cover;
}

.profile-info {
  text-align: center;

  .user-name {
    margin: 0;
    font-weight: 450;
    font-size: 16px;
    color: black;
  }

  .invited-by {
    color: #555;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .invited-by-name {
    font-size: 15px;
    margin-top: 0;
    font-weight: 400;
    font-size: 15px;
  }

  .selected-ticket-name {
    margin-bottom: 5px;
    margin-top: 25px;
    font-weight: 600;
    font-size: 17px;
    color: black;
  }
}

// .profile-info h2 {
//   margin-top: 16px;
//   margin-bottom: 4px;
//   font-size: 25px;
// }

.QR-optional-text {
  margin-top: 0;
  font-size: 12px;
  text-align: center;
}

// .QR-code-container {
//   display: inline-block;
//   width: fit-content;
//   padding: 10px 20px;
//   border: 2px solid #ccc;
//   border-radius: 5px;
//   background-color: #fff;
//   box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
//   font-size: 16px;
//   font-weight: bold;

//   p {
//     text-align: center;
//     font-size: 12px;
//   }
// }

.QR-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: bold;
}

.QR-content {
  display: flex; /* Flex container for QR code and "R" paragraph */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.QR-reduce-type-text-container {
}

.QR-reduce-type-text {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 22px;
  color: #dc3535;
}

.QR-comps-type-text {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 22px;
  color: #006400;
}

.message-text {
  font-size: 10px;
  color: #777;
}

.code {
  text-align: center;
  font-size: 12px;
  margin-bottom: 0;
}

.text-container {
  margin-top: 10px;
  text-align: center;

  .venue-text {
    font-size: 12px;
    margin-bottom: 0;
  }
}

.sign-in-button {
  background-color: #4b0082;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.payment-button {
  background-color: #635bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  margin-top: 15px;
  font-size: 15px;
  font-weight: bold;
  // margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.custom-popup-heading {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 18px;
  color: #4b0082;
}

.form-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-message-container {
  display: inline;
  font-size: 11px;
  color: #ff0000;
  align-self: flex-end;
}
