@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
  70% {
    transform: translateY(-7px);
  }
  80% {
    transform: translateY(-3px);
  }
}

.popup-bounce-content {
  animation: bounce 1s ease;
}

.gbl-popup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .gbl-popup-heading {
    font-size: 20px;
    font-weight: 500;
    color: #4b0082;
    margin-bottom: 15px;
  }

  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 30px;
    color: inherit;
  }
}
